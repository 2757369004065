<template>
  <div class="my-subscribe">
    <div class="subscribe"><span>我的订阅</span><span>订阅的政策有更新时，会通过微信/短信提醒您</span></div>
    <div class="content" v-if="list.length>0">
      <div class="title" v-for="(item, i) in list" :key="i" @click="goToPolicy(item)">
        <div>
          <div style="width: 990px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;"><span v-if="item.update" style="color: #ff0000;">【已更新】</span>{{ item.title }}</div>
          <div><span>{{ item.fwDepartment }}</span><span>{{ item.dispatchDate }}</span></div>
        </div>
        <div>&gt;</div>
      </div>
      <div
        v-if="list.length >= 10"
        class="more"
      >
        <div ref="more" class="showMore" @click="showMore">
          + 展开更多
        </div>
      </div>
    </div>
    <!-- 数据加载框 -->
    <div class="loading"
    v-show="loading"
    v-loading="loading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(255, 255, 255, 0.7)">
    </div>
    <div v-if="!loading && list.length==0">
      <el-empty description="暂无内容"></el-empty>
    </div>
  </div>
</template>

<script>
import { request } from '../../../network';
import { getMemberId } from '@/assets/public/utils/token';
export default {
  data () {
    return {
      list: [],
      pageNo: 1,
      size: 10,
      flag: true,
      loading: false
    };
  },
  mounted () {},
  created () {
    this.getMySubscribe();
  },
  computed: {
  },
  // inject:["reload"],
  methods: {
    async getMySubscribe () {
      this.loading=true
      const { data: res } = await request({
        method: 'GET',
        url: '/pcp/intelligentPush/mySubscribe',
        params: {
          pageNo: this.pageNo,
          size: this.size,
          id: getMemberId()
        }
      });
      if (res.code !== 200) return this.$message.error('数据获取失败');
      this.list = res.data;
      this.loading=false;
      // this.reload();
    },
    goToPolicy (item) {
      let url = item.type === 0 ? '/formaldetails' : '/faguidetails';
      this.$router.push({
        path: url,
        query: {
          id: item.id
        }
      });
    },
    async showMore () {
      if(this.flag){
        this.pageNo ++;
        const { data: res } = await request({
          method: 'GET',
          url: '/pcp/intelligentPush/mySubscribe',
          params: {
            pageNo: this.pageNo,
            size: this.size,
            id: getMemberId()
          }
        });
        if (res.code !== 200) return this.$message.error('数据获取失败');
        this.list = [ ...this.list,...res.data ];
        if(this.list.length < this.pageNo * this.size){
          this.$refs.more.innerHTML = '暂无更多内容';
          this.$refs.more.style.cursor = 'auto';
          this.flag = false;
        }
      }
    }
  }
};
</script>

<style lang="less" scoped>
.my-subscribe {
  border: 1px solid #B3BBC8;
  border-radius: 5px;
  .subscribe {
    display: flex;
    height: 55px;
    line-height: 55px;
    background-color: #F1F6FF;
    padding: 0 28px 0 20px;
    border-radius: 5px 5px 0 0;
    span:nth-child(1) {
      flex: 1;
    }
    span:nth-child(2) {
      font-size: 14px;
      color: #156ED0;
    }
  }
  .content {
    padding: 0 30px 43px 30px;
    box-sizing: border-box;
    .title {
      display: flex;
      padding: 25px 0 25px 10px;
      border-bottom: 1px solid #B8B8B8;
      cursor: pointer;
      &>div:nth-child(1) {
        div:nth-child(2) {
          font-size: 14px;
          margin-top: 15px;
          color: #B8B8B8;
          span {
            margin-right: 25px;
          }
        }
      }
      &>div:nth-child(2) {
        flex: 1;
        text-align: end;
        align-self: center;
        font-size: 18px;
        color: #B8B8B8;
      }
    }
    .more{
      background: #F9F9F9;
      .showMore {
        margin: 0 auto;
        width: 90px;
        text-align: center;
        padding: 16px 0;
        color: #156ED0;
        font-size: 14px;
        cursor: pointer;
      }
    }
  }
  .loading{
    height: 300px;
  }
}
</style>
